exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/aboutUs.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-careers-js": () => import("./../../../src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-cloud-services-js": () => import("./../../../src/templates/cloudServices.js" /* webpackChunkName: "component---src-templates-cloud-services-js" */),
  "component---src-templates-connectivity-js": () => import("./../../../src/templates/connectivity.js" /* webpackChunkName: "component---src-templates-connectivity-js" */),
  "component---src-templates-consulting-advisory-js": () => import("./../../../src/templates/consultingAdvisory.js" /* webpackChunkName: "component---src-templates-consulting-advisory-js" */),
  "component---src-templates-contact-center-js": () => import("./../../../src/templates/contactCenter.js" /* webpackChunkName: "component---src-templates-contact-center-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-internet-services-js": () => import("./../../../src/templates/internetServices.js" /* webpackChunkName: "component---src-templates-internet-services-js" */),
  "component---src-templates-partnership-js": () => import("./../../../src/templates/partnership.js" /* webpackChunkName: "component---src-templates-partnership-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacyPolicy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-quote-request-js": () => import("./../../../src/templates/quoteRequest.js" /* webpackChunkName: "component---src-templates-quote-request-js" */),
  "component---src-templates-timeless-technology-js": () => import("./../../../src/templates/timelessTechnology.js" /* webpackChunkName: "component---src-templates-timeless-technology-js" */)
}

